export const JOB_TITLES = [
  { label: 'Ingegnere', value: 'J01' },
  { label: 'Architetto', value: 'J02' },
  { label: 'Avvocato', value: 'J03' },
  { label: 'Giornalista', value: 'J04' },
];
export function getJobTitleTypesLabel(value: string): string {
  const item = JOB_TITLES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const GENDERS = [
  { label: 'Maschio', value: 'M' },
  { label: 'Femmina', value: 'F' },
  { label: 'Altro', value: 'X' },
];
export function getGenderTypesLabel(value: string): string {
  const item = GENDERS.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const PREF_COMM_LANGUAGES = [
  { label: 'Italiano', value: 'IT' },
  { label: 'Inglese', value: 'EN' },
  { label: 'Spagnolo', value: 'ES' },
  { label: 'Francese', value: 'FR' },
  { label: 'Tedesco', value: 'DE' },
];
export function getPrefCommLanguageTypesLabel(value: string): string {
  const item = PREF_COMM_LANGUAGES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
