<div class="top-bar">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="page-title">{{tpConfig?.touchpointCode}} - {{tpConfig?.tgTouchpointConfigCode}}</span>
</div>

<mat-card class="scrollable-card">
  <mat-card-content>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Codice touchpoint</mat-label>
        <input matInput [value]="tpConfig?.touchpointCode" required readonly disabled>
      </mat-form-field>

     <br>

      <mat-form-field appearance="outline">
        <mat-label>Codice manifestazione</mat-label>
        <input matInput [value]="tpConfig?.exhibitionCode" readonly disabled>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Codice macromanifestazione</mat-label>
        <input matInput [value]="tpConfig?.exhibitionGroupCode" readonly disabled>
      </mat-form-field>

      <br>
      <mat-form-field>
        <mat-label>Descrizione</mat-label>
        <input matInput formControlName="description" />
      </mat-form-field>

      <br>

      <mat-form-field appearance="fill">
        <mat-label>Codice TP TrustGuardian</mat-label>
        <input matInput formControlName="tgTouchpointConfigCode" required>
      </mat-form-field>

      <br>
      <mat-checkbox formControlName="validate">Salta validazione</mat-checkbox>

      <br>
      <br>

      <button *appHasRole="['admin','owner']" mat-raised-button color="warn" type="button" (click)="deleteEdition()">Elimina</button>
      <!--      <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="editionForm.invalid || isLoading">Salva</button>-->
      <button *appHasRole="['admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="formGroup.invalid || isSubmitting">
        <ng-container *ngIf="!isSubmitting">Salva</ng-container>
        <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
      </button>

    </form>
  </mat-card-content>
</mat-card>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>
