<div class="top-bar">
  <button mat-icon-button (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="page-title">{{person?.firstName}} {{person?.lastName}} - {{person?.email}}</span>
</div>

<mat-card>
  <mat-tab-group (selectedTabChange)="onTabChange($event)">

    <!--informazioni principali-->
    <mat-tab label="Informazioni principali">
      <br />
      <form [formGroup]="personForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="full-field">
          <mat-label>ID</mat-label>
          <input matInput [value]="person?.id" required readonly disabled>
        </mat-form-field>

        <br>


        <mat-form-field appearance="outline">
          <mat-label>email</mat-label>
          <input matInput [value]="person?.email" required readonly disabled>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Fiera ID</mat-label>
          <input matInput formControlName="fieraId">
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput formControlName="firstName">
          <mat-error *ngIf="personForm.get('firstName')?.hasError('required')">
            Inserisci il nome
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Cognome</mat-label>
          <input matInput formControlName="lastName">
          <mat-error *ngIf="personForm.get('lastName')?.hasError('required')">
            Inserisci il cognome
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Codice Fiscale</mat-label>
          <input matInput formControlName="fiscalCode">
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline">
          <mat-label>Codice touchpoint creazione</mat-label>
          <input matInput [value]="person?.touchpointCode" required readonly disabled>
        </mat-form-field>

        <br>

<!--        <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="personForm.invalid || isLoading">Salva</button>-->
        <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="personForm.invalid || isLoading || isSubmitting">
          <ng-container *ngIf="!isSubmitting">Salva</ng-container>
          <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
        </button>
      </form>
    </mat-tab>

    <!--privacy-->
    <mat-tab label="Privacy">
      <!-- Privacy content goes here -->
      <div *ngIf="privacyData">
        <br />
        <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" (click)="openPrivacyEventDialog()">Aggiungi evento privacy</button>

        <br>
        <br />

        <mat-form-field appearance="outline">
          <mat-label>Lingua principale</mat-label>
          <input matInput [value]="getActionLanguage(privacyData.language || '')" readonly>
        </mat-form-field>

        <br>

        <h3>Consensi</h3>
        <table mat-table [dataSource]="privacyData.consents_register" class="mat-elevation-z8">
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">{{element.code}}</td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Valore</th>
            <td mat-cell *matCellDef="let element">{{getEventTypeConsentLabel(element.value)}}</td>
          </ng-container>
          <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef>Data Ora</th>
            <td mat-cell *matCellDef="let element">{{element.datetime}}</td>
          </ng-container>
          <ng-container matColumnDef="expiration_datetime">
            <th mat-header-cell *matHeaderCellDef>Data scadenza</th>
            <td mat-cell *matCellDef="let element">{{element.expiration_datetime}}</td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Versione</th>
            <td mat-cell *matCellDef="let element">{{element.version}}</td>
          </ng-container>
          <ng-container matColumnDef="is_current_version">
            <th mat-header-cell *matHeaderCellDef>Ultima versione</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [checked]="element.is_current_version" disabled></mat-checkbox>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['code', 'value', 'datetime', 'expiration_datetime', 'version', 'is_current_version']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['code', 'value', 'datetime', 'expiration_datetime', 'version', 'is_current_version'];"></tr>
        </table>

        <h3>Informative</h3>
        <table mat-table [dataSource]="privacyData.policies" class="mat-elevation-z8">
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">{{getEventTypeLabel(element.type)}}</td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">{{element.code}}</td>
          </ng-container>
          <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef>Data Ora</th>
            <td mat-cell *matCellDef="let element">{{element.datetime}}</td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Versione</th>
            <td mat-cell *matCellDef="let element">{{element.version}}</td>
          </ng-container>
          <ng-container matColumnDef="is_current_version">
            <th mat-header-cell *matHeaderCellDef>Ultima versione</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [checked]="element.is_current_version" disabled></mat-checkbox>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['type', 'code', 'datetime', 'version', 'is_current_version']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['type', 'code', 'datetime', 'version', 'is_current_version'];"></tr>
        </table>

        <h3>Terms & Conditions</h3>
        <table mat-table [dataSource]="privacyData.documents" class="mat-elevation-z8">
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">{{getEventTypeLabel(element.type)}}</td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">{{element.code}}</td>
          </ng-container>
          <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef>Data Ora</th>
            <td mat-cell *matCellDef="let element">{{element.datetime}}</td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Versione</th>
            <td mat-cell *matCellDef="let element">{{element.version}}</td>
          </ng-container>
          <ng-container matColumnDef="is_current_version">
            <th mat-header-cell *matHeaderCellDef>Ultima versione</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox [checked]="element.is_current_version" disabled></mat-checkbox>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['type', 'code', 'datetime', 'version', 'is_current_version']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['type', 'code', 'datetime', 'version', 'is_current_version'];"></tr>
        </table>
      </div>
    </mat-tab>

    <!--profilo personale-->
    <mat-tab label="Profilo personale">
      <br />
        <form [formGroup]="personProfileForm" (ngSubmit)="onSubmitProfile()">

          <mat-form-field appearance="outline">
            <mat-label>Luogo di nascita</mat-label>
            <input matInput formControlName="birthPlace">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Data di nascita</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthDate" placeholder="dd/MM/yyyy">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Intervallo d'età</mat-label>
            <mat-select formControlName="ageRange">
              <mat-option *ngFor="let option of ageRanges" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Genere</mat-label>
            <mat-select formControlName="gender">
              <mat-option *ngFor="let gender of genders" [value]="gender.value">
                {{ gender.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br>

          <mat-form-field appearance="outline">
            <mat-label>Indirizzo</mat-label>
            <input matInput formControlName="address">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Città</mat-label>
            <input matInput formControlName="city">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>CAP</mat-label>
            <input matInput formControlName="zipCode">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Provincia</mat-label>
            <input type="text" matInput [matAutocomplete]="autoProvince" formControlName="province" />
            <mat-autocomplete #autoProvince="matAutocomplete">
              <mat-option *ngFor="let province of filteredProvinces | async" [value]="province.value">
                {{ province.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Nazione ISO2</mat-label>
            <input type="text" matInput [matAutocomplete]="autoCountry" formControlName="countryIso2" />
            <mat-autocomplete #autoCountry="matAutocomplete">
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country.value">
                {{ country.label }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <br>

          <mat-form-field appearance="outline">
            <mat-label>Lingua preferita</mat-label>
            <mat-select formControlName="prefCommLanguage">
              <mat-option *ngFor="let lang of prefCommLanguages" [value]="lang.value">
                {{ lang.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Titolo di studio</mat-label>
            <input matInput formControlName="qualification">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Qualifica professionale</mat-label>
            <mat-select formControlName="jobTitle">
              <mat-option *ngFor="let jobTitle of jobTitles" [value]="jobTitle.value">
                {{ jobTitle.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br>

          <mat-form-field appearance="outline">
            <mat-label>Website personale</mat-label>
            <input matInput formControlName="website">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>LinkedIn</mat-label>
            <input matInput formControlName="linkedIn">
          </mat-form-field>

          <br>

          <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="personProfileForm.invalid || isLoading">Salva</button>
        </form>
      </mat-tab>

    <!--contatti personali-->
    <mat-tab label="Contatti personali">
      <br />
      <div class="table-container">
        <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" (click)="openCreateContactDialog()">Aggiungi nuovo contatto</button>
        <br />
        <br>

        <table mat-table [dataSource]="personContacts" class="mat-elevation-z8">
          <ng-container matColumnDef="contactType">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element" (click)="openEditContactDialog(element.id)">{{getContactTypesLabel(element.contactType)}}</td>
          </ng-container>
          <ng-container matColumnDef="contactValue">
            <th mat-header-cell *matHeaderCellDef>Valore</th>
            <td mat-cell *matCellDef="let element" (click)="openEditContactDialog(element.id)">{{element.contactValue}}</td>
          </ng-container>
          <ng-container matColumnDef="isPreferred">
            <th mat-header-cell *matHeaderCellDef>Preferito</th>
            <td mat-cell *matCellDef="let element" (click)="openEditContactDialog(element.id)">
              <mat-checkbox [checked]="element.isPreferred" disabled></mat-checkbox>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['contactType', 'contactValue', 'isPreferred']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['contactType', 'contactValue', 'isPreferred'];"></tr>
        </table>
        <mat-paginator [length]="totalContacts" [pageSize]="paginationLimitContacts" (page)="onPageChangeContacts($event)" >
        </mat-paginator>
      </div>
      <div *ngIf="personContacts.length === 0">
        <p>Nessun contatto registrato</p>
      </div>
    </mat-tab>

    <!--profili professionali-->
    <mat-tab label="Profili professionali">
      <br />
      <div class="table-container">
          <div matTooltip="È possibile aggiungere al massimo un profilo professionale" [matTooltipDisabled]="false" matTooltipPosition="left">
            <button *appHasRole="['editor','admin','owner']" mat-raised-button color="primary" (click)="openCreateCompanyProfileDialog()" [disabled]="!(personCompanyProfiles.length === 0)">
              Aggiungi nuovo profilo aziendale
            </button>
          </div>
          <!--<br>-->
        <br />
          <table mat-table [dataSource]="personCompanyProfiles" class="mat-elevation-z8">
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef>Nome Azienda</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{element.company.name}}</td>
            </ng-container>
            <ng-container matColumnDef="companyVatNumber">
              <th mat-header-cell *matHeaderCellDef>Partita IVA</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{element.company.vatNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="businessRole">
              <th mat-header-cell *matHeaderCellDef>Ruolo</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{getBusinessRoleTypesLabel(element.businessRole)}}</td>
            </ng-container>
            <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef>Testata stampa/brand</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{element.brand}}</td>
            </ng-container>
            <ng-container matColumnDef="website">
              <th mat-header-cell *matHeaderCellDef>Sito web</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{element.website}}</td>
            </ng-container>
            <ng-container matColumnDef="companyCountryIso2">
              <th mat-header-cell *matHeaderCellDef>Nazione</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{getCountryTypesLabel(element.company.countryIso2)}}</td>
            </ng-container>
            <ng-container matColumnDef="companyPhone">
              <th mat-header-cell *matHeaderCellDef>Contatto aziendale</th>
              <td mat-cell *matCellDef="let element" (click)="openEditCompanyProfileDialog(element.id)">{{element.company.phone}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['companyName', 'companyVatNumber', 'businessRole', 'brand', 'website', 'companyCountryIso2', 'companyPhone']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['companyName', 'companyVatNumber', 'businessRole', 'brand', 'website', 'companyCountryIso2', 'companyPhone'];"></tr>
          </table>
          <mat-paginator [length]="totalCompanyProfiles" [pageSize]="paginationLimitCompanyProfiles" (page)="onPageChangeCompanyProfiles($event)">
          </mat-paginator>
        </div>
        <div *ngIf="personCompanyProfiles.length === 0">
          <p>Nessun profilo professionale registrato</p>
        </div>
      </mat-tab>

    <!--ingressi touchpoint-->
    <mat-tab label="Ingressi touchpoint">
      <div class="table-container">
        <table mat-table [dataSource]="touchpointIngresses" class="mat-elevation-z8">
          <ng-container matColumnDef="editionCode">
            <th mat-header-cell *matHeaderCellDef>Codice edizione</th>
            <td mat-cell *matCellDef="let element">{{element.editionCode}}</td>
          </ng-container>
          <ng-container matColumnDef="ingressRole">
            <th mat-header-cell *matHeaderCellDef>Ruolo</th>
            <td mat-cell *matCellDef="let element">{{getTouchpointPersonIngressRoleTypesLabel(element.ingressRole)}}</td>
          </ng-container>
          <ng-container matColumnDef="touchpointCode">
            <th mat-header-cell *matHeaderCellDef>Codice touchpoint</th>
            <td mat-cell *matCellDef="let element">{{element.touchpointCode}}</td>
          </ng-container>
          <ng-container matColumnDef="personType">
            <th mat-header-cell *matHeaderCellDef>Tipologia persona</th>
            <td mat-cell *matCellDef="let element">{{element.personType}}</td>
          </ng-container>
          <ng-container matColumnDef="ingressDate">
            <th mat-header-cell *matHeaderCellDef>Data Ora</th>
            <td mat-cell *matCellDef="let element">{{element.ingressDate}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['editionCode', 'ingressRole', 'touchpointCode', 'personType', 'ingressDate']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['editionCode', 'ingressRole', 'touchpointCode', 'personType', 'ingressDate'];"></tr>
        </table>
        <mat-paginator [length]="totalTouchpointIngresses" [pageSize]="paginationLimitTouchpointIngresses" (page)="onPageChange($event)">
        </mat-paginator>
      </div>
      <div *ngIf="touchpointIngresses.length === 0">
        <p>Nessun ingresso registrato</p>
      </div>
    </mat-tab>

    <!--Legal entities-->
    <mat-tab label="Legal entities">
      <br />
      <mat-list>
        <mat-list-item *ngFor="let legalEntity of personLegalEntities" class="no-hover">
          {{ legalEntity.name }}
        </mat-list-item>
      </mat-list>
    </mat-tab>

    <!--Playground-->
    <mat-tab label="Playground">

      <h3>Wrapper lettura</h3>

      <form [formGroup]="playgroundForm" (ngSubmit)="onSubmitPlayground()">
        <mat-form-field>
          <mat-label>Touchpoint Code</mat-label>
          <input matInput formControlName="touchpointCode" required />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Exhibition Code</mat-label>
          <input matInput formControlName="exhibitionCode" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Exhibition Group Code</mat-label>
          <input matInput formControlName="exhibitionGroupCode" />
        </mat-form-field>
        <br>
        <mat-form-field>
          <mat-label>Include Payload Resources</mat-label>
          <mat-select formControlName="includePayloadResources">
            <mat-option value="0">No</mat-option>
            <mat-option value="1">Yes</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Locale</mat-label>
          <input matInput formControlName="locale" />
        </mat-form-field>

        <br>

        <button *appHasRole="['viewer', 'editor','admin','owner']" mat-raised-button color="primary" type="submit" [disabled]="playgroundForm.invalid || isLoading || isSubmitting">
          <ng-container *ngIf="!isSubmitting">Run</ng-container>
          <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
        </button>

        <p *ngIf="elapsedTime">Tempo di risposta: {{ elapsedTime }} ms</p>
        <p *ngIf="elapsedTime">Il tempo di risposta è approssimativo, per conoscere il valore esatto analizzare il network delle chiamate nel browser.</p>
      </form>
      <div *ngIf="playgroundResponse" class="playground-response-container full-field-80 d-block">
        <label>Response</label>
        <pre><code [innerHTML]="playgroundResponse | highlight: 'json'"></code></pre>
      </div>
    </mat-tab>

  </mat-tab-group>
</mat-card>

<div *ngIf="isLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>
