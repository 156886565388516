import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

export function handlerSnackbarApiError(
  snackbar: MatSnackBar,
  err: any,
  lambdas: Function[] = [],
  defaultMessage?: string,
  position: { horizontal: MatSnackBarHorizontalPosition; vertical: MatSnackBarVerticalPosition } = { horizontal: 'center', vertical: 'bottom' },
  timeout: number = 10e3
): void {
  console.error(err);

  lambdas.forEach(lambda => {
    try {
      lambda();
    } catch (e) {
      console.error(e);
    }
  });
  let showingMessage = '';
  if (defaultMessage) {
    showingMessage = defaultMessage || 'È avvenuto un errore inaspettato';
  } else {
    const { error, headers, message, name, ok, status, statusText, url } = err;
    if (error) {
      const { error_message, message, statusCode, error_code, error_description } = error;
      if (statusCode === 400 || status === 400) {
        if (error_description) {
          showingMessage = `Errore API Trust Guardian: "Codice errore ${error_code} - ${error_description}"`;
        } else {
          const [firstError] = message;
          if (firstError && firstError.message) showingMessage = typeof error === 'string' ? error + '. ' + firstError.message : firstError.message;
        }
      } else if (statusCode === 401 || status === 401) {
        showingMessage = 'Unauthorized';
      } else if (statusCode === 403 || status === 403) {
        showingMessage = 'Forbidden';
      } else if (statusCode === 404 || status === 404) {
        showingMessage = message || 'Risorsa non trovata';
      } else if (statusCode === 409 || status === 409) {
        showingMessage = message || 'Conflitto';
      } else if (statusCode === 412 || status === 412) {
        showingMessage = 'Selezionare una Legal Entity';
      } else if (statusCode === 500 || status === 500) {
        showingMessage = 'Internal server error';
      } else {
        showingMessage = error_message ? error_message + '. ' + (message || '') : '';
      }
    } else showingMessage = err.message || showingMessage;
  }
  showingMessage = showingMessage || defaultMessage || 'È avvenuto un errore inaspettato';

  snackbar.openFromComponent(SnackbarComponent, {
    data: { message: showingMessage, duration: timeout },
    duration: timeout,
    horizontalPosition: position.horizontal,
    verticalPosition: position.vertical,
  });
}
