export const EVENT_CATEGORIES = [
  { value: 'policies', label: 'Informative privacy' },
  { value: 'documents', label: 'Terms & Conditions' },
  { value: 'purposes', label: 'Finalità' },
];
export function getEventCategoryLabel(value: string): string {
  const item = EVENT_CATEGORIES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const EVENT_TYPES_POLICIES = [{ label: 'Presa visione', value: 'policy-acknowledgment' }];
export function getEventTypePolicyLabel(value: string): string {
  const item = EVENT_TYPES_POLICIES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const EVENT_TYPES_DOCUMENTS = [{ label: 'Presa visione', value: 'document-acknowledgment' }];
export function getEventTypeDocumentLabel(value: string): string {
  const item = EVENT_TYPES_DOCUMENTS.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const EVENT_TYPES_PURPOSES = [
  { label: 'Consenso accettato', value: 'consent' },
  { label: 'Consenso rifiutato', value: 'withdraw-consent' },
  { label: 'Consenso proposto', value: 'proposed-consent' },
];
export function getEventTypePurposeLabel(value: string): string {
  const item = EVENT_TYPES_PURPOSES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const EVENT_TYPES = [
  { label: 'Consenso accettato', value: 'consent' },
  { label: 'Consenso rifiutato', value: 'withdraw-consent' },
  { label: 'Consenso proposto', value: 'proposed-consent' },
  { label: 'Presa visione informativa', value: 'policy-acknowledgment' },
  { label: 'Presa visione termini e condizioni', value: 'document-acknowledgment' },
];
export function getEventTypeLabel(value: string): string {
  const item = EVENT_TYPES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
export const EVENT_TYPE_CONSENT_VALUES = [
  { label: 'Accettato', value: 'on' },
  { label: 'Rifiutato', value: 'off' },
  { label: 'Proposto', value: 'proposed' },
  { label: 'Scaduto', value: 'expired' },
];
export function getEventTypeConsentLabel(value: string): string {
  const item = EVENT_TYPE_CONSENT_VALUES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
// todo: mettere lista completa dopo modellazione
export const PROOFS = [
  { label: 'IP', value: 'IP' },
  { label: 'URL', value: 'URL' },
  { label: 'FILE_EMAIL', value: 'PF3' },
];
export function getProofLabel(value: string): string {
  const item = PROOFS.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
// todo: mettere lista completa dopo modellazione
export const SOURCES = [
  { label: 'TOUCHPOINT', value: 'TOUCHPOINT' },
  { label: 'NOTE', value: 'SF3' },
];
export function getSourceLabel(value: string): string {
  const item = SOURCES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const ACTION_LANGUAGES = [
  { label: 'Italiano', value: 'it' },
  { label: 'Inglese', value: 'en' },
];
export function getActionLanguageLabel(value: string): string {
  const item = ACTION_LANGUAGES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
