import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimingService {
  private elapsedTime: number | null = null;

  setEstimatedElapsedTime(time: number): void {
    this.elapsedTime = Math.round(time * 0.8);
  }

  getEstimatedElapsedTime(): number | null {
    return this.elapsedTime;
  }
}
