<h1 mat-dialog-title>
  Nuovo profilo aziendale
  <button mat-button mat-dialog-close>X</button>
</h1>
<div mat-dialog-content>
  <form [formGroup]="companyProfileForm">
    <div formGroupName="company">
      <mat-form-field>
        <mat-label>Ragione sociale</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Partita IVA</mat-label>
        <input matInput formControlName="vatNumber" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Testata stampa/brand</mat-label>
        <input matInput formControlName="brand" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Ruolo aziendale</mat-label>
        <mat-select formControlName="businessRole">
          <mat-option *ngFor="let role of businessRoles" [value]="role.value">
            {{ role.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sito web professionale</mat-label>
        <input matInput formControlName="website" />
      </mat-form-field>
    </div>
    <div formGroupName="company">
      <mat-form-field>
        <mat-label>Sito Web</mat-label>
        <input matInput formControlName="website" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Telefono</mat-label>
        <input matInput formControlName="phone" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nazione</mat-label>
        <input type="text" matInput [matAutocomplete]="autoCountry" formControlName="countryIso2" />
        <mat-autocomplete #autoCountry="matAutocomplete">
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.value">
            {{ country.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Indirizzo</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Città</mat-label>
        <input matInput formControlName="city" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>CAP</mat-label>
        <input matInput formControlName="zipCode" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Provincia</mat-label>
        <input type="text" matInput [matAutocomplete]="autoProvince" formControlName="province" />
        <mat-autocomplete #autoProvince="matAutocomplete">
          <mat-option *ngFor="let province of filteredProvinces | async" [value]="province.value">
            {{ province.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Numero Dipendenti</mat-label>
        <input matInput formControlName="numberEmployees" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Fatturato annuo</mat-label>
        <input matInput formControlName="sales" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Codice AGEM</mat-label>
        <input matInput formControlName="agemCode" />
      </mat-form-field>
    </div>
    <br />
    <div formArrayName="personContacts">
      <div *ngFor="let contact of personContacts.controls; let i = index" [formGroupName]="i">
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="contactType" required>
            <mat-option *ngIf="contactTypes.length === 0" disabled>
              Tutti i tipi di contatto sono stati aggiunti
            </mat-option>
            <mat-option *ngFor="let type of contactTypes" [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Valore</mat-label>
          <input matInput formControlName="contactValue" />
        </mat-form-field>
        <mat-checkbox formControlName="isPreferred">Preferito</mat-checkbox>
        <button mat-icon-button color="warn" type="button" (click)="removeContact(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <button mat-raised-button color="primary" type="button" (click)="addContact()">Aggiungi contatto</button>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Annulla</button>
  <button mat-button (click)="onSubmit()" [disabled]="companyProfileForm.invalid">Salva</button>
</div>
