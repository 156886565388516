export const BUSINESS_ROLES = [
  { label: 'Titolare/ Amministratore Delegato / Direttore Generale', value: 'B01' },
  { label: 'Libero professionista / Consulente / Agente', value: 'B02' },
  { label: 'Tecnico / Impiegato', value: 'B03' },
  { label: 'Direttore / Manager', value: 'B04' },
  { label: 'Responsabile Marketing/Comunicazione', value: 'B05' },
  { label: 'Responsabile Acquisti', value: 'B06' },
  { label: 'Responsabile Ricerca E Sviluppo', value: 'B07' },
  { label: 'Responsabile Vendite', value: 'B08' },
  { label: 'N.D.', value: 'B99' },
];
export function getBusinessRoleTypesLabel(value: string): string {
  const item = BUSINESS_ROLES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const PROVINCES = [
  { value: 'AG', label: 'AGRIGENTO' },
  { value: 'AL', label: 'ALESSANDRIA' },
  { value: 'AN', label: 'ANCONA' },
  { value: 'AO', label: 'AOSTA' },
  { value: 'AP', label: 'ASCOLI PICENO' },
  { value: 'AQ', label: 'AQUILA' },
  { value: 'AR', label: 'AREZZO' },
  { value: 'AT', label: 'ASTI' },
  { value: 'AV', label: 'AVELLINO' },
  { value: 'BA', label: 'BARI' },
  { value: 'BG', label: 'BERGAMO' },
  { value: 'BI', label: 'BIELLA' },
  { value: 'BL', label: 'BELLUNO' },
  { value: 'BN', label: 'BENEVENTO' },
  { value: 'BO', label: 'BOLOGNA' },
  { value: 'BR', label: 'BRINDISI' },
  { value: 'BS', label: 'BRESCIA' },
  { value: 'BT', label: 'BARLETTA-ANDRIA-TRANI' },
  { value: 'BZ', label: 'BOLZANO' },
  { value: 'CA', label: 'CAGLIARI' },
  { value: 'CB', label: 'CAMPOBASSO' },
  { value: 'CE', label: 'CASERTA' },
  { value: 'CH', label: 'CHIETI' },
  { value: 'CI', label: 'CARBONIA-IGLESIAS' },
  { value: 'CL', label: 'CALTANISSETTA' },
  { value: 'CN', label: 'CUNEO' },
  { value: 'CO', label: 'COMO' },
  { value: 'CR', label: 'CREMONA' },
  { value: 'CS', label: 'COSENZA' },
  { value: 'CT', label: 'CATANIA' },
  { value: 'CZ', label: 'CATANZARO' },
  { value: 'EN', label: 'ENNA' },
  { value: 'FC', label: "FORLI'-CESENA" },
  { value: 'FE', label: 'FERRARA' },
  { value: 'FG', label: 'FOGGIA' },
  { value: 'FI', label: 'FIRENZE' },
  { value: 'FM', label: 'FERMO' },
  { value: 'FR', label: 'FROSINONE' },
  { value: 'GE', label: 'GENOVA' },
  { value: 'GO', label: 'GORIZIA' },
  { value: 'GR', label: 'GROSSETO' },
  { value: 'IM', label: 'IMPERIA' },
  { value: 'IS', label: 'ISERNIA' },
  { value: 'KR', label: 'CROTONE' },
  { value: 'LC', label: 'LECCO' },
  { value: 'LE', label: 'LECCE' },
  { value: 'LI', label: 'LIVORNO' },
  { value: 'LO', label: 'LODI' },
  { value: 'LT', label: 'LATINA' },
  { value: 'LU', label: 'LUCCA' },
  { value: 'MB', label: 'MONZA-BRIANZA' },
  { value: 'MC', label: 'MACERATA' },
  { value: 'ME', label: 'MESSINA' },
  { value: 'MI', label: 'MILANO' },
  { value: 'MN', label: 'MANTOVA' },
  { value: 'MO', label: 'MODENA' },
  { value: 'MS', label: 'MASSA-CARRARA' },
  { value: 'MT', label: 'MATERA' },
  { value: 'NA', label: 'NAPOLI' },
  { value: 'NO', label: 'NOVARA' },
  { value: 'NU', label: 'NUORO' },
  { value: 'OG', label: 'OGLIASTRA' },
  { value: 'OR', label: 'ORISTANO' },
  { value: 'OT', label: 'OLBIA-TEMPIO' },
  { value: 'PA', label: 'PALERMO' },
  { value: 'PC', label: 'PIACENZA' },
  { value: 'PD', label: 'PADOVA' },
  { value: 'PE', label: 'PESCARA' },
  { value: 'PG', label: 'PERUGIA' },
  { value: 'PI', label: 'PISA' },
  { value: 'PN', label: 'PORDENONE' },
  { value: 'PO', label: 'PRATO' },
  { value: 'PR', label: 'PARMA' },
  { value: 'PT', label: 'PISTOIA' },
  { value: 'PU', label: 'PESARO-URBINO' },
  { value: 'PV', label: 'PAVIA' },
  { value: 'PZ', label: 'POTENZA' },
  { value: 'RA', label: 'RAVENNA' },
  { value: 'RC', label: 'REGGIO CALABRIA' },
  { value: 'RE', label: 'REGGIO EMILIA' },
  { value: 'RG', label: 'RAGUSA' },
  { value: 'RI', label: 'RIETI' },
  { value: 'RM', label: 'ROMA' },
  { value: 'RN', label: 'RIMINI' },
  { value: 'RO', label: 'ROVIGO' },
  { value: 'SA', label: 'SALERNO' },
  { value: 'SI', label: 'SIENA' },
  { value: 'SO', label: 'SONDRIO' },
  { value: 'SP', label: 'LA SPEZIA' },
  { value: 'SR', label: 'SIRACUSA' },
  { value: 'SS', label: 'SASSARI' },
  { value: 'SU', label: 'SUD SARDEGNA' },
  { value: 'SV', label: 'SAVONA' },
  { value: 'TA', label: 'TARANTO' },
  { value: 'TE', label: 'TERAMO' },
  { value: 'TN', label: 'TRENTO' },
  { value: 'TO', label: 'TORINO' },
  { value: 'TP', label: 'TRAPANI' },
  { value: 'TR', label: 'TERNI' },
  { value: 'TS', label: 'TRIESTE' },
  { value: 'TV', label: 'TREVISO' },
  { value: 'UD', label: 'UDINE' },
  { value: 'VA', label: 'VARESE' },
  { value: 'VB', label: 'VERBANIA' },
  { value: 'VC', label: 'VERCELLI' },
  { value: 'VE', label: 'VENEZIA' },
  { value: 'VI', label: 'VICENZA' },
  { value: 'VR', label: 'VERONA' },
  { value: 'VS', label: 'MEDIOCAMPIDANO' },
  { value: 'VT', label: 'VITERBO' },
  { value: 'VV', label: 'VIBO VALENTIA' },
];
export function getProvinceTypesLabel(value: string): string {
  const item = PROVINCES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}

export const COUNTRIES = [
  { value: 'AD', label: 'Andorra' },
  { value: 'AE', label: 'Emirati Arabi Uniti' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AG', label: 'Antigua e Barbuda' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AL', label: 'Albania' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AO', label: 'Angola' },
  { value: 'AQ', label: 'Antartide' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AS', label: 'Samoa Americane' },
  { value: 'AT', label: 'Austria' },
  { value: 'AU', label: 'Australia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AX', label: 'Isole Åland' },
  { value: 'AZ', label: 'Azerbaigian' },
  { value: 'BA', label: 'Bosnia ed Erzegovina' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BE', label: 'Belgio' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BH', label: 'Bahrein' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BL', label: 'Saint-Barthélemy' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BQ', label: 'Isole BES' },
  { value: 'BR', label: 'Brasile' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BV', label: 'Isola Bouvet' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BY', label: 'Bielorussia' },
  { value: 'BZ', label: 'Belize' },
  { value: 'CA', label: 'Canada' },
  { value: 'CC', label: 'Isole Cocos e Keeling' },
  { value: 'CD', label: 'Repubblica Democratica del Congo' },
  { value: 'CF', label: 'Repubblica Centrafricana' },
  { value: 'CG', label: 'Repubblica del Congo' },
  { value: 'CH', label: 'Svizzera' },
  { value: 'CI', label: "Costa d'Avorio" },
  { value: 'CK', label: 'Isole Cook' },
  { value: 'CL', label: 'Cile' },
  { value: 'CM', label: 'Camerun' },
  { value: 'CN', label: 'Cina' },
  { value: 'CO', label: 'Colombia' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CV', label: 'Capo Verde' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CX', label: 'Isola del Natale' },
  { value: 'CY', label: 'Cipro' },
  { value: 'CZ', label: 'Repubblica Ceca' },
  { value: 'DE', label: 'Germania' },
  { value: 'DJ', label: 'Gibuti' },
  { value: 'DK', label: 'Danimarca' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Repubblica Dominicana' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EE', label: 'Estonia' },
  { value: 'EG', label: 'Egitto' },
  { value: 'EH', label: 'Sahara Occidentale' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'ES', label: 'Spagna' },
  { value: 'ET', label: 'Etiopia' },
  { value: 'FI', label: 'Finlandia' },
  { value: 'FJ', label: 'Figi' },
  { value: 'FK', label: 'Isole Falkland' },
  { value: 'FM', label: 'Stati Federati di Micronesia' },
  { value: 'FO', label: 'Isole Fær Øer' },
  { value: 'FR', label: 'Francia' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GB', label: 'Regno Unito' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GE', label: 'Georgia' },
  { value: 'GF', label: 'Guyana francese' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibilterra' },
  { value: 'GL', label: 'Groenlandia' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GP', label: 'Guadalupa' },
  { value: 'GQ', label: 'Guinea Equatoriale' },
  { value: 'GR', label: 'Grecia' },
  { value: 'GS', label: 'Georgia del Sud e isole Sandwich meridionali' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GU', label: 'Guam' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HM', label: 'Isole Heard e McDonald' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HR', label: 'Croazia' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HU', label: 'Ungheria' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IE', label: 'Irlanda' },
  { value: 'IL', label: 'Israele' },
  { value: 'IM', label: 'Isola di Man' },
  { value: 'IN', label: 'India' },
  { value: 'IO', label: "Territori Britannici dell'Oceano Indiano" },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IR', label: 'Iran' },
  { value: 'IS', label: 'Islanda' },
  { value: 'IT', label: 'Italia' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JM', label: 'Giamaica' },
  { value: 'JO', label: 'Giordania' },
  { value: 'JP', label: 'Giappone' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KG', label: 'Kirghizistan' },
  { value: 'KH', label: 'Cambogia' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KM', label: 'Comore' },
  { value: 'KN', label: 'Saint Kitts e Nevis' },
  { value: 'KP', label: 'Corea del Nord' },
  { value: 'KR', label: 'Corea del Sud' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KY', label: 'Isole Cayman' },
  { value: 'KZ', label: 'Kazakistan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LB', label: 'Libano' },
  { value: 'LC', label: 'Santa Lucia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LT', label: 'Lituania' },
  { value: 'LU', label: 'Lussemburgo' },
  { value: 'LV', label: 'Lettonia' },
  { value: 'LY', label: 'Libia' },
  { value: 'MA', label: 'Marocco' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MD', label: 'Moldavia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MF', label: 'Saint-Martin' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MH', label: 'Isole Marshall' },
  { value: 'MK', label: 'Macedonia del Nord' },
  { value: 'ML', label: 'Mali' },
  { value: 'MM', label: 'Birmania' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MO', label: 'Macao' },
  { value: 'MP', label: 'Isole Marianne Settentrionali' },
  { value: 'MQ', label: 'Martinica' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MT', label: 'Malta' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MV', label: 'Maldive' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MX', label: 'Messico' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MZ', label: 'Mozambico' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NC', label: 'Nuova Caledonia' },
  { value: 'NE', label: 'Niger' },
  { value: 'NF', label: 'Isola Norfolk' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Paesi Bassi' },
  { value: 'NO', label: 'Norvegia' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NU', label: 'Niue' },
  { value: 'NZ', label: 'Nuova Zelanda' },
  { value: 'OM', label: 'Oman' },
  { value: 'PA', label: 'Panama' },
  { value: 'PE', label: 'Perù' },
  { value: 'PF', label: 'Polinesia Francese' },
  { value: 'PG', label: 'Papua Nuova Guinea' },
  { value: 'PH', label: 'Filippine' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PL', label: 'Polonia' },
  { value: 'PM', label: 'Saint-Pierre e Miquelon' },
  { value: 'PN', label: 'Isole Pitcairn' },
  { value: 'PR', label: 'Porto Rico' },
  { value: 'PS', label: 'Stato di Palestina' },
  { value: 'PT', label: 'Portogallo' },
  { value: 'PW', label: 'Palau' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Riunione' },
  { value: 'RO', label: 'Romania' },
  { value: 'RS', label: 'Serbia' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Ruanda' },
  { value: 'SA', label: 'Arabia Saudita' },
  { value: 'SB', label: 'Isole Salomone' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SE', label: 'Svezia' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SH', label: "Sant'Elena, Isola di Ascensione e Tristan da Cunha" },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SJ', label: 'Svalbard e Jan Mayen' },
  { value: 'SK', label: 'Slovacchia' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SN', label: 'Senegal' },
  { value: 'SO', label: 'Somalia' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SS', label: 'Sudan del Sud' },
  { value: 'ST', label: 'São Tomé e Príncipe' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'SX', label: 'Sint Maarten' },
  { value: 'SY', label: 'Siria' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'TC', label: 'Isole Turks e Caicos' },
  { value: 'TD', label: 'Ciad' },
  { value: 'TF', label: 'Territori Francesi del Sud' },
  { value: 'TG', label: 'Togo' },
  { value: 'TH', label: 'Thailandia' },
  { value: 'TJ', label: 'Tagikistan' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TL', label: 'Timor Est' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TR', label: 'Turchia' },
  { value: 'TT', label: 'Trinidad e Tobago' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TW', label: 'Repubblica di Cina' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'UA', label: 'Ucraina' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UM', label: 'Isole minori esterne degli Stati Uniti' },
  { value: 'US', label: "Stati Uniti d'America" },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VA', label: 'Città del Vaticano' },
  { value: 'VC', label: 'Saint Vincent e Grenadine' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VG', label: 'Isole Vergini britanniche' },
  { value: 'VI', label: 'Isole Vergini americane' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'WF', label: 'Wallis e Futuna' },
  { value: 'WS', label: 'Samoa' },
  { value: 'YE', label: 'Yemen' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'ZA', label: 'Sudafrica' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
  { value: 'AN', label: 'Netherlands Antilles' },
  { value: 'XK', label: 'Kosovo' },
];
export function getCountryTypesLabel(value: string): string {
  const item = COUNTRIES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
