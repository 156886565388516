import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiCmdService } from '../../../../services/api-cmd.service';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle, MatDialogClose } from '@angular/material/dialog';
import { DataCreatePrivacyEventsApiRequestDto, DataListPrivacyCodesApiResponseDto } from '@packages/api-cmd-type-definitions';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf, NgForOf, AsyncPipe } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { HasRoleModule } from '../../../../modules/has-role.module';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import {
  ACTION_LANGUAGES,
  EVENT_TYPES,
  getActionLanguageLabel,
  PROOFS,
  SOURCES,
  EVENT_CATEGORIES,
  EVENT_TYPES_DOCUMENTS,
  EVENT_TYPES_POLICIES,
  EVENT_TYPES_PURPOSES,
  getEventCategoryLabel,
  getEventTypeDocumentLabel,
  getEventTypePolicyLabel,
  getEventTypePurposeLabel,
} from '../../../../constants/privacy.constants';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-privacy-event-dialog',
  standalone: true,
  imports: [
    MatLabel,
    MatError,
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgIf,
    NgForOf,
    MatButton,
    MatIcon,
    MatIconButton,
    MatSuffix,
    HasRoleModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatSelect,
    MatOption,
    MatProgressSpinner,
    MatDialogClose,
    MatAutocompleteTrigger,
    MatAutocomplete,
    AsyncPipe,
  ],
  templateUrl: './privacy-event-dialog.component.html',
  styleUrl: './privacy-event-dialog.component.scss',
})
export class PrivacyEventDialogComponent implements OnInit {
  privacyEventForm: FormGroup;
  public isSubmitting = false;
  privacyEventTypes = EVENT_TYPES;
  privacyEventCategories = EVENT_CATEGORIES;
  privacyEventTypesPolicies = EVENT_TYPES_POLICIES;
  privacyEventTypesDocuments = EVENT_TYPES_DOCUMENTS;
  privacyEventTypesPurposes = EVENT_TYPES_PURPOSES;
  privacyProofs = PROOFS;
  privacySources = SOURCES;
  actionLanguages = ACTION_LANGUAGES;
  getActionLanguage = getActionLanguageLabel;
  getEventCategory = getEventCategoryLabel;
  getEventTypePolicy = getEventTypePolicyLabel;
  getEventTypeDocument = getEventTypeDocumentLabel;
  getEventTypePurpose = getEventTypePurposeLabel;
  privacyCodesPolicies: DataListPrivacyCodesApiResponseDto['data'] = [];
  privacyCodesDocuments: DataListPrivacyCodesApiResponseDto['data'] = [];
  privacyCodesPurposes: DataListPrivacyCodesApiResponseDto['data'] = [];
  filteredCodes: Observable<DataListPrivacyCodesApiResponseDto['data']>[] = [];
  uploadedFileNames: { [key: number]: string } = {};

  constructor(
    private fb: FormBuilder,
    private apiCmdService: ApiCmdService,
    public dialogRef: MatDialogRef<PrivacyEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personId: string },
    private snackbar: MatSnackBar
  ) {
    this.privacyEventForm = this.fb.group({
      action: this.fb.group({
        language: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
        date: ['', Validators.required],
        touchpoint: [''],
        proof: this.fb.array([
          this.fb.group({
            code: ['', Validators.required],
            value: ['', Validators.required],
          }),
        ]),
        source: this.fb.array([
          this.fb.group({
            code: ['', Validators.required],
            value: ['', Validators.required],
          }),
        ]),
      }),
      events: this.fb.array([this.createEventGroup()]),
    });
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.fetchPrivacyCodes();
  }

  createEventGroup(): FormGroup {
    return this.fb.group({
      category: ['', Validators.required],
      type: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  get proofArray(): FormArray {
    return this.privacyEventForm.get('action.proof') as FormArray;
  }

  get sourceArray(): FormArray {
    return this.privacyEventForm.get('action.source') as FormArray;
  }

  get eventsArray(): FormArray {
    return this.privacyEventForm.get('events') as FormArray;
  }

  addProof(): void {
    this.proofArray.push(
      this.fb.group({
        code: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  removeProof(index: number): void {
    this.proofArray.removeAt(index);
  }

  addSource(): void {
    this.sourceArray.push(
      this.fb.group({
        code: ['', Validators.required],
        value: ['', Validators.required],
      })
    );
  }

  removeSource(index: number): void {
    this.sourceArray.removeAt(index);
  }

  get events(): FormArray {
    return this.privacyEventForm.get('events') as FormArray;
  }

  addEvent(): void {
    this.eventsArray.push(this.createEventGroup());
    this.filteredCodes.push(new Observable());
  }

  removeEvent(index: number): void {
    this.eventsArray.removeAt(index);
    this.filteredCodes.splice(index, 1);
  }

  onCategoryChange(event: any, index: number): void {
    const selectedCategory = event.value;
    const eventGroup = this.events.at(index) as FormGroup;
    eventGroup.get('type')?.setValue('');
    eventGroup.get('code')?.setValue('');
    this.filteredCodes[index] = eventGroup.get('code')!.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.filterCodes(value, selectedCategory))
    );
  }

  getEventTypeFromCategory(category: string): { label: string; value: string }[] {
    switch (category) {
      case 'policies':
        return this.privacyEventTypesPolicies;
      case 'documents':
        return this.privacyEventTypesDocuments;
      case 'purposes':
        return this.privacyEventTypesPurposes;
      default:
        return [];
    }
  }

  fetchPrivacyCodes(): void {
    this.apiCmdService.listPrivacyCodesPolicies({}).subscribe(response => {
      this.privacyCodesPolicies = response.data;
    });
    this.apiCmdService.listPrivacyCodesDocuments({}).subscribe(response => {
      this.privacyCodesDocuments = response.data;
    });
    this.apiCmdService.listPrivacyCodesPurposes({}).subscribe(response => {
      this.privacyCodesPurposes = response.data;
    });
  }

  filterCodes(value: string, category: string): DataListPrivacyCodesApiResponseDto['data'] {
    const filterValue = value.toLowerCase();
    const codes = this.getEventCodes(category);
    return codes.filter(code => code.name.toLowerCase().includes(filterValue));
  }

  getEventCodes(category: string): DataListPrivacyCodesApiResponseDto['data'] {
    switch (category) {
      case 'policies':
        return this.privacyCodesPolicies;
      case 'documents':
        return this.privacyCodesDocuments;
      case 'purposes':
        return this.privacyCodesPurposes;
      default:
        return [];
    }
  }

  handleFileInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const allowedTypes = ['image/*', 'application/pdf', 'text/*'];
      if (allowedTypes.some(type => file.type.match(type))) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result as string;
          console.log(base64);
          this.proofArray.at(index).get('value')?.setValue(base64);
          this.uploadedFileNames[index] = file.name;
        };
        reader.readAsDataURL(file);
      } else {
        console.error('Invalid file type');
      }
    }
  }

  onSubmit(): void {
    if (this.privacyEventForm.valid) {
      this.isSubmitting = true;
      const dto: DataCreatePrivacyEventsApiRequestDto['data'] = this.privacyEventForm.value;
      if (dto.action.proof?.length === 0) {
        delete dto.action.proof;
      }
      if (dto.action.source?.length === 0) {
        delete dto.action.source;
      }
      if (!dto.action.touchpoint) {
        delete dto.action.touchpoint;
      }
      if (dto.events) {
        dto.events = dto.events.map(event => ({ code: event.code, type: event.type }));
      }
      this.apiCmdService.createEventPrivacyPerson(this.data.personId, { data: dto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.dialogRef.close('success');
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
