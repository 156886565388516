export const TOUCHPOINT_PERSON_INGRESS_ROLE_TYPES = [
  { label: 'Allestitore', value: 'A' },
  { label: 'Buyer', value: 'B' },
  { label: 'Espositore', value: 'E' },
  { label: 'Fornitore', value: 'F' },
  { label: 'Organizzatore', value: 'O' },
  { label: 'Stampa', value: 'S' },
  { label: 'Agente', value: 'T' },
  { label: 'Visitatore', value: 'V' },
];
export function getTouchpointPersonIngressRoleTypesLabel(value: string): string {
  const item = TOUCHPOINT_PERSON_INGRESS_ROLE_TYPES.find(r => r.value === value);
  return item ? item.label : value || 'Unknown';
}
