<div class="filter-container">
  <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
    <h3>Filtri<button class="close-dialog" mat-button mat-dialog-close>X</button></h3>

    <!--email-->
    <mat-form-field appearance="outline">
      <mat-label>email</mat-label>
      <input matInput formControlName="email" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <!--fieraid-->
    <mat-form-field appearance="outline">
      <mat-label>FieraID</mat-label>
      <input matInput formControlName="fieraId" placeholder="Ricerca ID completo" />
    </mat-form-field>

    <br />

    <!--Nome-->
    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput formControlName="firstName" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <!--Cognome-->
    <mat-form-field appearance="outline">
      <mat-label>Cognome</mat-label>
      <input matInput formControlName="lastName" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <!--Ragione sociale-->
    <mat-form-field appearance="outline">
      <mat-label>Ragione sociale</mat-label>
      <input matInput formControlName="companyName" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <!--Codice fiscale-->
    <mat-form-field appearance="outline">
      <mat-label>Codice fiscale</mat-label>
      <input matInput formControlName="fiscalCode" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <!--Codice persona-->
<!--    <mat-form-field appearance="outline">
      <mat-label>Codice persona</mat-label>
      <input matInput formControlName="personCode" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />-->

    <!--ID-->
    <mat-form-field appearance="outline">
      <mat-label>ID</mat-label>
      <input matInput formControlName="id" placeholder="Ricerca ID completo (uuid)" />
    </mat-form-field>

    <br />

    <!--Codice touchpoint-->
    <mat-form-field appearance="outline">
      <mat-label>Codice touchpoint</mat-label>
      <input matInput formControlName="touchpointCode" placeholder="Ricerca testo libero" />
    </mat-form-field>

    <br />

    <br />

    <button mat-raised-button color="primary" (click)="resetFilters()">
      <mat-icon fontIcon="filter_alt_off"></mat-icon>
      Pulisci filtri
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="filterForm.invalid">Applica</button>
  </form>
</div>
