import { Pipe, PipeTransform } from '@angular/core';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-json';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, language: string): string {
    if (!value) return '';
    return highlight(value, languages[language], language);
  }
}
