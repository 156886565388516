import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TimingService } from '../services/timing.service';

@Injectable()
export class TimingInterceptor implements HttpInterceptor {
  constructor(private timingService: TimingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();
    return next.handle(req).pipe(
      tap(event => {
        const elapsedTime = Date.now() - startTime;
        if (event instanceof HttpResponse) {
          this.timingService.setEstimatedElapsedTime(elapsedTime);
        }
      })
    );
  }
}
