import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCmdService } from '../../../services/api-cmd.service';
import { GetPersonResponse } from '@packages/api-cmd-type-definitions/dist/person/get-person.dto';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatCard } from '@angular/material/card';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { DataGetAllInfoApiResponseDto, DataUpdatePersonApiRequestDto, GetAllInfoResponse, QueryTouchpointInfoApiRequestDto } from '@packages/api-cmd-type-definitions';
import { PrivacyRegisterResponse } from '@packages/api-cmd-type-definitions/lib/privacy/registers.dto';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyEventDialogComponent } from './privacy-event-dialog/privacy-event-dialog.component';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { MatCheckbox } from '@angular/material/checkbox';
import { GetPersonProfileResponse } from '@packages/api-cmd-type-definitions/lib/person-profiles/get-person-profile.dto';
import { DataUpsertPersonProfileApiRequestDto } from '@packages/api-cmd-type-definitions';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { GetTouchpointPersonIngressResponse } from '@packages/api-cmd-type-definitions/dist/touchpoint-person-ingresses/get-touchpoint-person-ingresses.dto';
import { MatPaginator } from '@angular/material/paginator';
import { GetPersonContactResponse } from '@packages/api-cmd-type-definitions/dist/person-contacts/get-person-contact.dto';
import { ContactCreateDialogComponent } from './contact-create-dialog/contact-create-dialog.component';
import { ContactEditDialogComponent } from './contact-edit-dialog/contact-edit-dialog.component';
import { GetPersonCompanyProfileResponse } from '@packages/api-cmd-type-definitions/dist/person-company-profile/get-person-company-profile.dto';
import { CompanyProfileEditDialogComponent } from './company-profile-edit-dialog/company-profile-edit-dialog.component';
import { CompanyProfileCreateDialogComponent } from './company-profile-create-dialog/company-profile-create-dialog.component';
import { HasRoleModule } from '../../../modules/has-role.module';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { ACTION_LANGUAGES, EVENT_TYPE_CONSENT_VALUES, getActionLanguageLabel, getEventTypeConsentLabel, getEventTypeLabel } from '../../../constants/privacy.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { handlerSnackbarApiError } from '../../../utils/snackbar.handler';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatList, MatListItem } from '@angular/material/list';
import { LegalEntity } from '../../../types/legal-entity.type';
import { SessionStorageService } from '../../../services/session-storage.service';
import { CONTACT_TYPES, getContactTypesLabel } from '../../../constants/contact.constants';
import { MatTooltip } from '@angular/material/tooltip';
import { TimingService } from '../../../services/timing.service';
import { HighlightPipe } from '../../../pipes/highlight.pipe';
import { getTouchpointPersonIngressRoleTypesLabel } from '../../../constants/touchpointPersonIngress.constants';
import { COUNTRIES, getBusinessRoleTypesLabel, getCountryTypesLabel, getProvinceTypesLabel, PROVINCES } from '../../../constants/companyProfile.constants';
import { GENDERS, getGenderTypesLabel, getJobTitleTypesLabel, getPrefCommLanguageTypesLabel, JOB_TITLES, PREF_COMM_LANGUAGES } from '../../../constants/profile.constants';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-edit-person',
  standalone: true,
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.scss'],
  imports: [
    MatError,
    MatLabel,
    MatIconButton,
    MatIcon,
    MatCard,
    MatTabGroup,
    MatTab,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgIf,
    MatButton,
    NgForOf,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatCheckbox,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatOption,
    MatSelect,
    MatPaginator,
    HasRoleModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
    MatProgressSpinner,
    MatList,
    MatListItem,
    MatTooltip,
    HighlightPipe,
    AsyncPipe,
    MatAutocomplete,
    MatAutocompleteTrigger,
  ],
})
export class EditPersonComponent implements OnInit {
  // person
  personForm: FormGroup;
  person: GetPersonResponse | undefined;
  // privacy
  privacyData: PrivacyRegisterResponse | undefined;
  getEventTypeLabel = getEventTypeLabel;
  actionLanguages = ACTION_LANGUAGES;
  getActionLanguage = getActionLanguageLabel;
  eventTypeConsentValues = EVENT_TYPE_CONSENT_VALUES;
  getEventTypeConsentLabel = getEventTypeConsentLabel;
  // profile
  personProfileForm: FormGroup;
  personProfile: GetPersonProfileResponse | undefined;
  ageRanges: string[] = ['1-17', '18-25', '26-35', '36-45', '46-55', '56-65', '66-75', '76-85', '86-95'];
  jobTitles = JOB_TITLES;
  getJobTitleTypesLabel = getJobTitleTypesLabel;
  genders = GENDERS;
  getGenderTypesLabel = getGenderTypesLabel;
  prefCommLanguages = PREF_COMM_LANGUAGES;
  getPrefCommLanguageTypesLabel = getPrefCommLanguageTypesLabel;
  provinces = PROVINCES;
  getPronvinceLabel = getProvinceTypesLabel;
  filteredProvinces!: Observable<any[]>;
  countries = COUNTRIES;
  filteredCountries!: Observable<any[]>;
  // contacts
  personContacts: GetPersonContactResponse[] = [];
  @ViewChild('paginatorContacts') paginatorContacts!: MatPaginator;
  paginationLimitContacts = 64;
  currentPageIndexContacts = 0;
  totalContacts = 0;
  contactTypes = CONTACT_TYPES;
  getContactTypesLabel = getContactTypesLabel;
  // company profiles
  personCompanyProfiles: GetPersonCompanyProfileResponse[] = [];
  totalCompanyProfiles = 0;
  paginationLimitCompanyProfiles = 64;
  @ViewChild('paginatorCompanyProfiles') paginatorCompanyProfiles!: MatPaginator;
  getBusinessRoleTypesLabel = getBusinessRoleTypesLabel;
  getProvinceTypesLabel = getProvinceTypesLabel;
  getCountryTypesLabel = getCountryTypesLabel;

  // touchpoint ingresses
  touchpointIngresses: GetTouchpointPersonIngressResponse[] = [];
  totalTouchpointIngresses = 0;
  @ViewChild(MatPaginator) paginatorTouchpointIngresses!: MatPaginator;
  paginationLimitTouchpointIngresses = 10;
  getTouchpointPersonIngressRoleTypesLabel = getTouchpointPersonIngressRoleTypesLabel;

  // legal entities
  private legalEntities: LegalEntity[] = this.sessionStorageService.getLegalEntities() || [];
  personLegalEntities: LegalEntity[] = [];

  // playground wrapper
  getAllInfo: GetAllInfoResponse | undefined;
  playgroundForm: FormGroup;
  playgroundResponse: string = '';
  elapsedTime: number | null = null;

  // loading status
  public isLoading = true;
  // submitting status
  public isSubmitting = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private apiCmdService: ApiCmdService,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private sessionStorageService: SessionStorageService,
    private timingService: TimingService
  ) {
    this.personForm = this.fb.group({
      fieraId: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      fiscalCode: [''],
    });

    this.personProfileForm = this.fb.group({
      prefCommLanguage: [''],
      jobTitle: [''],
      linkedIn: [''],
      website: [''],
      ageRange: [''],
      birthDate: [''],
      birthPlace: [''],
      address: [''],
      city: [''],
      province: [''],
      zipCode: [''],
      countryIso2: [''],
      gender: [''],
      qualification: [''],
    });

    this.playgroundForm = this.fb.group({
      touchpointCode: ['', Validators.required],
      exhibitionCode: [''],
      exhibitionGroupCode: [''],
      includePayloadResources: ['0'],
      locale: [''],
    });
  }

  private setterIsLoading(value: boolean): () => void {
    return () => {
      this.isLoading = value;
    };
  }

  private setterIsSubmitting(value: boolean): () => void {
    return () => {
      this.isSubmitting = value;
    };
  }

  ngOnInit(): void {
    this.loadPerson();
  }

  navigateBack() {
    this.router.navigate(['/persone']);
  }

  onTabChange(event: any): void {
    if (event.index === 0) this.loadPerson();
    else if (event.index === 1) this.loadPrivacyData();
    else if (event.index === 2) this.loadPersonProfile();
    else if (event.index === 3) this.loadPersonContacts();
    else if (event.index === 4) this.loadPersonCompanyProfiles();
    else if (event.index === 5) this.loadTouchpointIngresses();
  }

  // person

  loadPerson(): void {
    const personId = this.route.snapshot.paramMap.get('id');
    if (personId) {
      this.isLoading = true;
      this.apiCmdService.getPerson(personId, {}).subscribe({
        next: response => {
          this.person = response.data;
          this.personForm.patchValue(this.person);
          this.personLegalEntities = this.person.legalEntityPersons
            .map(legalEntityPerson => {
              return this.legalEntities.find(legalEntity => legalEntity.code === legalEntityPerson.legalEntityCode);
            })
            .filter(l => l) as LegalEntity[];
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  onSubmit(): void {
    if (this.personForm.valid && this.person) {
      this.isSubmitting = true;
      const updateDto: DataUpdatePersonApiRequestDto['data'] = {
        fieraId: this.personForm.value.fieraId || undefined,
        firstName: this.personForm.value.firstName || undefined,
        lastName: this.personForm.value.lastName || undefined,
        fiscalCode: this.personForm.value.fiscalCode === '' ? null : this.personForm.value.fiscalCode,
      };
      this.apiCmdService.updatePerson(this.person.id, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.loadPerson();
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  // privacy

  loadPrivacyData(): void {
    if (this.person) {
      this.isLoading = true;
      this.apiCmdService.getRegisterPrivacyPerson(this.person.id, { consents_register: '1', policies: '1', documents: '1' }).subscribe({
        next: response => {
          this.privacyData = response.data;
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  openPrivacyEventDialog(): void {
    const dialogRef = this.dialog.open(PrivacyEventDialogComponent, {
      width: '400px',
      data: { personId: this.person?.id },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.loadPrivacyData();
      }
    });
  }

  // profile
  private _filterProvinces(value: string): any[] {
    const filterValue = (value || '').toLowerCase();
    return this.provinces.filter(province => province.label.toLowerCase().includes(filterValue));
  }
  private _filterCountries(value: string): any[] {
    const filterValue = (value || '').toLowerCase();
    return this.countries.filter(country => country.label.toLowerCase().includes(filterValue));
  }

  loadPersonProfile(): void {
    if (this.person) {
      this.isLoading = true;
      this.apiCmdService.getPersonProfile(this.person.id).subscribe({
        next: response => {
          this.personProfile = response.data;
          const ageRange =
            this.personProfile.floorAgeRange && this.personProfile.ceilAgeRange ? `${this.personProfile.floorAgeRange}-${this.personProfile.ceilAgeRange}` : undefined;
          this.personProfileForm.patchValue({
            ...this.personProfile,
            ageRange: ageRange,
          });
          this.filteredProvinces = this.personProfileForm.get('province')!.valueChanges.pipe(
            startWith(''),
            map(value => this._filterProvinces(value))
          );
          this.filteredCountries = this.personProfileForm.get('countryIso2')!.valueChanges.pipe(
            startWith(''),
            map(value => this._filterCountries(value))
          );
          this.isLoading = false;
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
      });
    }
  }

  onSubmitProfile(): void {
    if (this.personProfileForm.valid && this.person) {
      const [floorAgeRange, ceilAgeRange] = this.personProfileForm.value.ageRange ? this.personProfileForm.value.ageRange.split('-') : ['', ''];
      const updateDto: DataUpsertPersonProfileApiRequestDto['data'] = {
        ...this.personProfileForm.value,
        floorAgeRange: floorAgeRange || undefined,
        ceilAgeRange: ceilAgeRange || undefined,
      };
      // @ts-expect-error TS7053
      delete updateDto['ageRange'];
      Object.keys(updateDto).forEach(key => {
        // ts-expect-error TS7053
        // if (!updateDto[key]) updateDto[key] = undefined;
        // @ts-expect-error TS7053
        if (updateDto[key] === '') updateDto[key] = null;
      });

      this.apiCmdService.upsertPersonProfile(this.person.id, { data: updateDto }).subscribe({
        next: () => {
          this.isSubmitting = false;
          this.loadPersonProfile();
        },
        error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsSubmitting(false)]),
      });
    }
  }

  // contacts

  loadPersonContacts(pageIndex: number = 0): void {
    if (this.person) {
      this.isLoading = true;
      const paginationSkip = pageIndex * this.paginationLimitContacts;
      this.apiCmdService
        .listPersonContacts(this.person.id, {
          paginationSkip,
          paginationLimit: this.paginationLimitContacts,
          fieldOrder: 'isPreferred',
          sortOrder: 'desc',
        })
        .subscribe({
          next: response => {
            this.personContacts = response.data;

            const len = response.data.length;

            if (pageIndex === 0) {
              if (len < this.paginationLimitContacts) this.totalContacts = len;
              else this.totalContacts = this.paginationLimitContacts * 2;
            } else if (len === this.paginationLimitContacts) {
              if ((pageIndex + 1) * this.paginationLimitContacts === this.totalContacts) {
                this.totalContacts += 128;
              }
            } else if (len < this.paginationLimitContacts) {
              this.totalContacts = pageIndex * this.paginationLimitContacts + len;
            }
            this.isLoading = false;
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
        });
    }
  }

  onPageChangeContacts(event: any): void {
    this.currentPageIndexContacts = event.pageIndex;
    this.loadPersonContacts(event.pageIndex);
  }

  openCreateContactDialog(): void {
    const dialogRef = this.dialog.open(ContactCreateDialogComponent, {
      width: '400px',
      data: { personId: this.person?.id, contacts: this.personContacts },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.totalContacts = 0;
        this.loadPersonContacts(this.currentPageIndexContacts);
      }
    });
  }

  openEditContactDialog(contactId: string): void {
    const dialogRef = this.dialog.open(ContactEditDialogComponent, {
      width: '400px',
      data: { personId: this.person?.id, contactId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPersonContacts(this.currentPageIndexContacts);
      }
    });
  }

  // company profiles

  loadPersonCompanyProfiles(pageIndex: number = 0): void {
    if (this.person) {
      this.isLoading = true;
      const paginationSkip = pageIndex * this.paginationLimitCompanyProfiles;
      this.apiCmdService
        .listPersonCompanyProfiles(this.person.id, {
          paginationSkip,
          paginationLimit: this.paginationLimitCompanyProfiles,
        })
        .subscribe({
          next: response => {
            this.personCompanyProfiles = response.data;

            const len = response.data.length;

            if (pageIndex === 0) {
              if (len < this.paginationLimitCompanyProfiles) this.totalCompanyProfiles = len;
              else this.totalCompanyProfiles = this.paginationLimitCompanyProfiles * 2;
            } else if (len === this.paginationLimitCompanyProfiles) {
              if ((pageIndex + 1) * this.paginationLimitCompanyProfiles === this.totalCompanyProfiles) {
                this.totalCompanyProfiles += 64;
              }
            } else if (len < this.paginationLimitCompanyProfiles) {
              this.totalCompanyProfiles = pageIndex * this.paginationLimitCompanyProfiles + len;
            }
            this.isLoading = false;
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
        });
    }
  }

  onPageChangeCompanyProfiles(event: any): void {
    this.loadPersonCompanyProfiles(event.pageIndex);
  }

  openEditCompanyProfileDialog(companyProfileId: string): void {
    const dialogRef = this.dialog.open(CompanyProfileEditDialogComponent, {
      width: '40%',
      data: { personId: this.person?.id, companyProfileId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPersonCompanyProfiles();
      }
    });
  }

  openCreateCompanyProfileDialog(): void {
    const dialogRef = this.dialog.open(CompanyProfileCreateDialogComponent, {
      width: '40%',
      data: { personId: this.person?.id },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPersonCompanyProfiles();
      }
    });
  }

  // touchpoint ingresses

  loadTouchpointIngresses(pageIndex: number = 0): void {
    if (this.person) {
      this.isLoading = true;
      const paginationSkip = pageIndex * this.paginationLimitTouchpointIngresses;
      this.apiCmdService
        .listTouchpointPersonIngresses(this.person.id, {
          paginationSkip,
          paginationLimit: this.paginationLimitTouchpointIngresses,
          fieldOrder: 'ingressDate',
          sortOrder: 'desc',
        })
        .subscribe({
          next: response => {
            this.touchpointIngresses = response.data;

            const len = response.data.length;

            if (pageIndex === 0) {
              if (len < this.paginationLimitTouchpointIngresses) this.totalTouchpointIngresses = len;
              else this.totalTouchpointIngresses = this.paginationLimitTouchpointIngresses * 10;
            } else if (len === this.paginationLimitTouchpointIngresses) {
              if ((pageIndex + 1) * this.paginationLimitTouchpointIngresses === this.totalTouchpointIngresses) {
                this.totalTouchpointIngresses += 100;
              }
            } else if (len < this.paginationLimitTouchpointIngresses) {
              this.totalTouchpointIngresses = pageIndex * this.paginationLimitTouchpointIngresses + len;
            }
            this.isLoading = false;
          },
          error: err => handlerSnackbarApiError(this.snackbar, err, [this.setterIsLoading(false)]),
        });
    }
  }

  onPageChange(event: any): void {
    this.loadTouchpointIngresses(event.pageIndex);
  }

  // playground

  onSubmitPlayground(): void {
    if (this.playgroundForm.valid) {
      this.isLoading = true;
      const query: QueryTouchpointInfoApiRequestDto = this.playgroundForm.value;
      Object.keys(query).forEach(key => {
        // @ts-expect-error TS7053
        if (query[key] === '' || query[key] === undefined) delete query[key];
      });
      this.apiCmdService
        .getAllInfo({
          ...query,
          email: this.person!.email,
        })
        .subscribe({
          next: (response: DataGetAllInfoApiResponseDto) => {
            this.elapsedTime = this.timingService.getEstimatedElapsedTime();
            this.playgroundResponse = JSON.stringify(response, null, 2);
            this.isLoading = false;
          },
          error: err => {
            this.elapsedTime = null;
            this.playgroundResponse = JSON.stringify(err.error, null, 2);
            this.isLoading = false;
          },
        });
    }
  }
}
