<h1 mat-dialog-title>
  Nuovo registro privacy
  <button mat-button mat-dialog-close>X</button>
</h1>
<mat-dialog-content>
  <form [formGroup]="privacyEventForm" (ngSubmit)="onSubmit()">

    <h4>Configurazione Touchpoint</h4>
    <div formGroupName="action">
      <mat-form-field>
        <mat-label>Trust Guardian Touchpoint Code</mat-label>
        <input matInput formControlName="touchpoint" />
      </mat-form-field>
    </div>

    <h4>Eventi privacy</h4>
    <div formArrayName="events">
      <div *ngFor="let event of events.controls; let i = index" [formGroupName]="i">
        <mat-form-field>
          <mat-label>Categoria evento</mat-label>
          <mat-select formControlName="category" (selectionChange)="onCategoryChange($event, i)">
            <mat-option *ngFor="let category of privacyEventCategories" [value]="category.value">
              {{ category.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="event.get('category')?.value">
          <mat-label>Tipo evento</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of getEventTypeFromCategory(event.get('category')?.value)" [value]="type.value">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="event.get('category')?.value">
          <mat-label>Codice evento</mat-label>
          <input matInput formControlName="code" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let code of filteredCodes[i] | async" [value]="code.code">
              {{ code.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <button mat-icon-button color="warn" type="button" (click)="removeEvent(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <button mat-raised-button color="primary" type="button" (click)="addEvent()">Aggiungi evento</button>
    <br />

    <div formGroupName="action">
      <mat-form-field>
        <mat-label>Lingua</mat-label>
        <mat-select formControlName="language">
          <mat-option *ngFor="let language of actionLanguages" [value]="language.value">
            {{ language.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Data e ora</mat-label>
        <input matInput formControlName="date" [matDatetimepicker]="picker" />
        <mat-datetimepicker-toggle matSuffix [for]="picker"></mat-datetimepicker-toggle>
        <mat-datetimepicker #picker type="datetime"></mat-datetimepicker>
      </mat-form-field>
    </div>

    <div formGroupName="action">

      <h4>Prove di genuinità</h4>

      <div formArrayName="proof">
        <div *ngFor="let proof of proofArray.controls; let i = index" [formGroupName]="i">
          <mat-form-field>
            <mat-label>Codice Proof</mat-label>
            <mat-select formControlName="code">
              <mat-option *ngFor="let proofType of privacyProofs" [value]="proofType.value">
                {{ proofType.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="proof.get('code')?.value !== 'PF3'">
            <mat-label>Valore Proof</mat-label>
            <input matInput formControlName="value" />
          </mat-form-field>

          <div *ngIf="proof.get('code')?.value === 'PF3'">
            <div *ngIf="uploadedFileNames[i]">{{ uploadedFileNames[i] }}</div>
            <button type="button" mat-raised-button (click)="fileInput.click()">Carica file</button>
            <input hidden (change)="handleFileInput($event, i)" #fileInput type="file" id="file" accept="image/*,application/pdf,text/*">
          </div>

          <button mat-icon-button color="warn" type="button" (click)="removeProof(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <br />

        <button mat-raised-button color="primary" type="button" (click)="addProof()">Aggiungi Proof</button>
      </div>

        <h4>Informazioni extra</h4>

        <div formArrayName="source">
          <div *ngFor="let source of sourceArray.controls; let i = index" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Codice Source</mat-label>
              <mat-select formControlName="code">
                <mat-option *ngFor="let sourceType of privacySources" [value]="sourceType.value">
                  {{ sourceType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Valore Source</mat-label>
              <input matInput formControlName="value" />
            </mat-form-field>

            <button mat-icon-button color="warn" type="button" (click)="removeSource(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <br />
          <button mat-raised-button color="primary" type="button" (click)="addSource()">Aggiungi Source</button>
        </div>
    </div>

    <br />

    <button mat-raised-button color="warn" type="button" (click)="onCancel()">Annulla</button>
    <!--    <button mat-raised-button color="primary" type="submit" [disabled]="privacyEventForm.invalid">Salva</button>-->
    <button mat-raised-button color="primary" type="submit" [disabled]="privacyEventForm.invalid || isSubmitting">
      <ng-container *ngIf="!isSubmitting">Salva</ng-container>
      <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
    </button>
  </form>
</mat-dialog-content>
