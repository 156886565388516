<div class="container">
  <div class="header">
    <button mat-raised-button color="primary" (click)="openFilterDialog()"><mat-icon fontIcon="search"></mat-icon>Ricerca</button>
    <button mat-raised-button color="primary" (click)="refreshList()">
      <mat-icon fontIcon="refresh"></mat-icon>
    </button>
    <button *appHasRole="['editor', 'admin', 'owner']" mat-raised-button color="primary" (click)="navigateToCreate()">Crea persona</button>
  </div>

  <div class="table-container">
    <cdk-virtual-scroll-viewport itemSize="50" class="viewport" (scrollend)="loadMoreData($event)">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8">
        <!--Row selection and clickable -->
        <mat-header-row *matHeaderRowDef="displayedColumns" sticky></mat-header-row>
        <!--          <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClick(row)"></mat-row>-->
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick($event, row)" (mouseup)="onMouseUp($event)"></mat-row>
        <!--Email-->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Email </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"
            ><span>{{ element.email }}</span></mat-cell
          >
        </ng-container>

        <!--Fiera ID-->
        <ng-container matColumnDef="fieraId">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Fiera ID </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"
            ><span>{{ element.fieraId }}</span></mat-cell
          >
        </ng-container>

        <!--Nome-->
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Nome </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"
            ><span>{{ element.firstName }}</span></mat-cell
          >
        </ng-container>

        <!--Cognome-->
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Cognome </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"
            ><span>{{ element.lastName }} </span></mat-cell
          >
        </ng-container>

        <!--Ragione sociale-->
        <ng-container matColumnDef="companyName">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Ragione sociale </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable">
            <span>{{ element.companyName }}</span></mat-cell
          >
        </ng-container>

        <!--Codice fiscale-->
        <ng-container matColumnDef="fiscalCode">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> Codice fiscale </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"
            ><span>{{ element.fiscalCode }}</span></mat-cell
          >
        </ng-container>

        <!--ID-->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header sticky> ID </mat-header-cell>
          <mat-cell *matCellDef="let element" class="selectable"
            ><span>{{ element.id }}</span></mat-cell
          >
        </ng-container>
      </mat-table>
    </cdk-virtual-scroll-viewport>

    <div *ngIf="isLoading" class="loader-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
